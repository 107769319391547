import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import BorderLine from "./borderline"

const axios = require('axios');

const Footer = ({ siteTitle }) => {

  const [emailValue, setEmailValue] = useState('');
  const [emailMessage, setEmailMessage] = useState('');

  const handleMailSubmit = () => {

    if(validateEmail(emailValue)){

      axios.post('https://story-spinner-hq6t.temp-dns.com/mugsgames-php/addcontact.php', {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data:{
          email: emailValue,
        }
      
      })
      .then(response => {
        
        switch(true){

          case response.data.id!==undefined:

            setEmailMessage('THANKS FOR JOINING!')

            setEmailValue('') 

          break;

          case response.data.code=="duplicate_parameter":
           
              setEmailMessage('EMAIL IS ALREADY ON THE LIST!')

          break


        }

        // console.log(response.data)
         
       })
      .catch(error => {
        console.log(error);
      });

      }
      else{

        setEmailMessage('INVALID EMAIL. PLEASE TRY AGAIN!')
      }

  }

  function validateEmail(email) {
    const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return res.test(String(email).toLowerCase());
  }

return (
  
  <footer>

    <BorderLine />
    <BorderLine />
    
    <nav>
      
      <ul className="links">
      <li><Link activeClassName="active" to="/terms/">TERMS & CONDITIONS</Link></li>
      <li><Link activeClassName="active" to="/privacy-policy/">PRIVACY POLICY</Link></li>
      <li><Link activeClassName="active" to="/shopping-policy/">SHOPPING POLICY</Link></li>
      <li><Link activeClassName="active" to="/refund-policy/">REFUND POLICY</Link></li>
      <li><Link activeClassName="active" to="/contact/">CONTACT</Link></li>
      </ul>

    </nav>

    <ul className="social">

      <li>
      <a rel="noreferrer" target="_blank" href="https://twitter.com/mugs_games">
      <StaticImage
      src="../images/twitter.png"
      width={30}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="MUGS GAMES"/>
      </a>
      </li>
      <li>
      <a rel="noreferrer" target="_blank" href="https://www.instagram.com/mugs_games">
      <StaticImage
      src="../images/instagram.png"
      width={30}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="MUGS GAMES"/>
      </a>
      </li>
      <li>
      <a rel="noreferrer" target="_blank" href="https://www.facebook.com/mugsgames">
      <StaticImage
      src="../images/facebook.png"
      width={30}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="MUGS GAMES"/>
      </a>
      </li>
      
    </ul>

    <ul className="mailinglist_holder">

      <li className="mailinglist">
      <input onChange={event => {
          setEmailValue(event.target.value)
      }}
      type="text" name="email" value={emailValue} /><input onClick={ handleMailSubmit } type="submit" value="SIGN UP" />
      </li>
     
    </ul>

    <ul className="mailinglist_holder">

      <li className="mailinglist_message">
      {emailMessage}
      </li>

    </ul>

  </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
