/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import CookieConsent from "react-cookie-consent";

import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import "./layout-custom.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      
      <div>
        <Header siteTitle="" />
        <main>{children}</main>
        <Footer />

        <CookieConsent
          containerClasses="consentHolder"
          buttonWrapperClasses="consentButtonClasses"
          declineButtonClasses=""
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="MMCookie"
          expires={150}
          enableDeclineButton
          onAccept={() => {
            console.log('accept!')
          }}
          onDecline={() => {
            console.log('declined!')
          }}
          
         >
          This website uses cookies to improve user experience. By using this website you consent to their usage in accordance with our privacy policy.
        
        </CookieConsent>


      </div>
      
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
