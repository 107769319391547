import * as React from "react"
// import PropTypes from "prop-types"

const BorderLine = ({ title }) => (
    
    <div className="borderline"></div>
    
)

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default BorderLine
