import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import BorderLine from "./borderline"
const axios = require('axios');

const Header = ({ siteTitle }) => {

  const [emailValue, setEmailValue] = useState('');
  const [emailMessage, setEmailMessage] = useState('');

  const handleMailSubmit = () => {

    if(validateEmail(emailValue)){

      axios.post('https://story-spinner-hq6t.temp-dns.com/mugsgames-php/addcontact.php', {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data:{
          email: emailValue,
        }
      
      })
      .then(response => {
        
        switch(true){

          case response.data.id!==undefined:

            setEmailMessage('Thanks for signing up!')

            setEmailValue('') 

          break;

          case response.data.code=="duplicate_parameter":
           
              setEmailMessage('Email is already on the list!')

          break


        }

        // console.log(response.data)
         
       })
      .catch(error => {
        console.log(error);
      });

      }
      else{

        setEmailMessage('Invalid email. Please try again!')
      }

  }

  function validateEmail(email) {
    const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return res.test(String(email).toLowerCase());
  }

return(
  <header>
    
    <div className="logo">

    <Link activeClassName="active" to="/">
    <StaticImage
      src="../images/mugsgames-logo.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="MUGS GAMES"
    />
    </Link>

    </div>

    <BorderLine />
    <BorderLine />

    <nav>
      <ul>
      
      <li><Link activeClassName="active" to="/">HOME</Link></li>
      <li><Link activeClassName="active" to="/about/">ABOUT</Link></li>
      <li><Link activeClassName="active" to="/shop/">SHOP</Link></li>
      <li><Link activeClassName="active" to="/contact/">CONTACT</Link></li>
      <li className="mailinglist"><input onChange={event => {
          setEmailValue(event.target.value)
      }}
      type="text" name="email" value={emailValue} /><input onClick={ handleMailSubmit } type="submit" value="SIGNUP" /></li>

      </ul>

       {emailMessage &&
      <div className="mailinglist_message">
      {emailMessage}
      </div>
      }


    </nav>

    <BorderLine />
    <BorderLine />

  </header>)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
